export const RECORDS_PER_PAGE = 20;

export const DEFAULT_FORM_VALUES = {
  title: '',
  type: '',
};

export const FLAG_TYPES = {
  tag: {
    value: 'tag',
    name: 'Tag',
    plural: 'Tags',
  },
  geoGraphicRegion: {
    value: 'geoGraphicRegion',
    name: 'Geo-Graphic Region',
    plural: 'Geo-Graphic Regions',
  },
  theme: {
    value: 'theme',
    name: 'Theme',
    plural: 'Themes',
  },
};

export const FLAG_TYPES_ARR = Object.keys(FLAG_TYPES).map((type) => {
  return {
    name: FLAG_TYPES[type].name,
    value: FLAG_TYPES[type].value,
    plural: FLAG_TYPES[type].plural,
  };
});

export const FLAG_TYPES_KEYS_ARR = Object.keys(FLAG_TYPES);
