import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {RECORDS_PER_PAGE} from '@/shared/constants/flagsConstants';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setFlagsList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    addFlagToList: (state, action) => {
      state.list.push(action.payload);
    },
    updateFlag: (state, action) => {
      const foundIndex = state.list.findIndex(
        ({id}) => id === action.payload.id
      );
      state.list[foundIndex] = {
        ...state.list[foundIndex],
        ...action.payload,
      };
    },
    deleteFlag(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setFlagsList,
  setCurrentData,
} = actions;

// Selectors
export const getAllFlags = (state) => state.flags.list;

export const getFlagById =
  ({flagId}) =>
  (state) =>
    state.flags.list.find(({id}) => {
      return id === flagId;
    });

// Fetch Flags
export const fetchFlags = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/flags',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setFlagsList(body?.flags));

  const initialData = body?.flags.slice(0, RECORDS_PER_PAGE);
  dispatch(actions.setCurrentData(initialData));
};

// Create Flag
export const createNewFlag = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: '/flags',
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.addFlagToList(body?.flag));
};

export const updateFlag = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: patchRequest,
    endpoint: `/flags/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.updateFlag(body?.flag));
};

// Delete Flag
export const deleteFlag = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/flags/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteFlag(data?.id));
};
