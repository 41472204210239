export const COLUMNS_HEADERS = [
  {id: 'name', key: 'name', label: 'Name'},
  {id: 'averageRating', key: 'averageRating', label: 'Rating'},
  {
    id: 'totalNumberOfReviews',
    key: 'totalNumberOfReviews',
    label: 'Reviews Count',
  },
];

export const RECORDS_PER_PAGE = 20;
