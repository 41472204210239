export const COLUMNS_HEADERS = [
  {id: 'image', key: 'image', label: 'Image'},
  {id: 'firstName', key: 'firstName', label: 'First Name'},
  {id: 'lastName', key: 'lastName', label: 'Last Name'},
  {id: 'tip', key: 'tip', label: 'Tip'},
];

export const RECORDS_PER_PAGE = 20;

export const DEFAULT_FORM_VALUES = {
  firstName: '',
  lastName: '',
  tip: '',
  image: '',
};
