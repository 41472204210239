import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {RECORDS_PER_PAGE} from '@/shared/constants/agentsConstants';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setAgentsList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    addAgentToList: (state, action) => {
      state.list.push(action.payload);
    },
    updateAgent: (state, action) => {
      const foundIndex = state.list.findIndex(
        ({id}) => id == action.payload.id
      );
      state.list[foundIndex] = {
        ...state.list[foundIndex],
        ...action.payload,
      };
    },
    deleteAgent(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setAgentsList,
  setCurrentData,
} = actions;

// Selectors
export const getAllAgents = (state) => state.agents.list;

export const getAgentById =
  ({agentId}) =>
  (state) =>
    state.agents.list.find(({id}) => {
      return id === agentId;
    });

// Fetch Agents
export const fetchAgents = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/agents',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setAgentsList(body?.agents));

  const initialData = body?.agents.slice(0, RECORDS_PER_PAGE);
  dispatch(actions.setCurrentData(initialData));
};

// Create Product
export const createNewAgent = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: '/agents',
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.addAgentToList(body?.agent));
};

export const updateAgent = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: patchRequest,
    endpoint: `/agents/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.updateAgent(body?.agent));
};

// Delete Agent
export const deleteAgent = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/agents/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteAgent(data?.id));
};
