import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '@/shared/utils/requests';
import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {RECORDS_PER_PAGE} from '@/shared/constants/hotelsConstants';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setHotelsList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    addHotelToList: (state, action) => {
      state.list.push(action.payload);
    },
    deleteHotel(state, action) {
      state.list = state.list?.filter(({id}) => id !== action.payload);
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {
  hasError,
  startLoading,
  stopLoading,
  setHotelsList,
  setCurrentData,
} = actions;

// Selectors
export const getAllHotels = (state) => state.hotels.list;

export const getHotelById =
  ({hotelId}) =>
  (state) =>
    state.hotels.list.find(({id}) => {
      return id === hotelId;
    });

// Fetch Hotels
export const fetchHotels = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: '/hotels',
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;

  dispatch(actions.setHotelsList(body?.hotels));

  const initialData = body?.hotels.slice(0, RECORDS_PER_PAGE);
  dispatch(actions.setCurrentData(initialData));
};

// Create Product
export const createOrAddHotel = (data) => async (dispatch, getState) => {
  const state = getState();
  const {name} = data;
  const list = state.hotels.list;

  let key;

  if (data?.name.includes(' ')) key = 'name';
  else key = 'id';

  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: postRequest,
    endpoint: '/hotels/find',
    payload: {...data, key},
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else {
    const foundHotel = list.find(
      ({name: hname, rateHawkId}) =>
        hname.toLowerCase() === name.toLowerCase() ||
        rateHawkId.toLowerCase() === name.toLowerCase()
    );
    if (!foundHotel) dispatch(actions.addHotelToList(body?.hotel));
    return body?.hotel;
  }
};

// Delete Hotel
export const deleteHotel = (data) => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: deleteRequest,
    endpoint: `/hotels/${data?.id}`,
    payload: data,
    toastMessage: {success: {show: true}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.deleteHotel(data?.id));
};
